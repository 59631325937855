<template>
  <panel title="Setting Your Tel Number">
    <!-- use component -->
    <form-phone-number-modal
      ref="FormPhoneNumberModal"
      @reloading="fetchPhoneData()"
    />
    <!-- end use component -->

    <data-table-local
      :columns="_columns"
      :items="items"
      @clickNew="$refs.FormPhoneNumberModal.show()"
      @clickView="clickView"
      @clickEdit="clickEdit"
      @clickDelete="clickDelete"
      :viewAble="false"
    />
  </panel>
</template>
<script>
export default {
  created() {
    this.fetchPhoneData()
  },
  data() {
    return {
      items: [],
    }
  },
  components: {
    FormPhoneNumberModal: () => import("./Components/FormPhoneNumberModal.vue"),
  },
  computed: {
    _columns() {
      return [
        {
          label: "Country ",
          field: "phone_country_code_data.country",
          thClass: "text-center",
          type: "string",
        },
        {
          label: "Country Code ",
          field: "phone_country_code_data.dial_code",
          thClass: "text-center",
          type: "string",
        },
        {
          label: "Phone Number",
          field: "phone",
          thClass: "text-center",
          type: "string",
        },
        {
          label: "Voice",
          field: "gender_text",
          thClass: "text-center",
          type: "string",
        },
        {
          label: "Total Toll",
          field: "count_toll",
          tdClass: "text-right",
          thClass: "text-center",
        },
      ]
    },
  },
  methods: {
    paypalCallback(data) {
      console.log(data)
    },
    clickView(data) {
      this.$refs.FormPhoneNumberModal.show(this.cp(data))
    },
    clickEdit(data) {
      this.$refs.FormPhoneNumberModal.show(this.cp(data))
    },
    async clickDelete(data) {
      try {
        const resp = await this.api.del(
          `/api/phoneData/${data.id}`,
          "Phone Datas",
          true
        )
        this.fetchPhoneData()
      } catch (error) {
        console.log(error)
      }
    },
    async fetchPhoneData() {
      try {
        this.items = await this.api.get("/api/phoneData")
      } catch (error) {
        console.error(error)
        return ""
      }
    },
  },
}
</script>
